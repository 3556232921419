.table-container {
  height: 96vh;
  overflow: auto;
}

.pagination {
  max-height: 5vh;
  width: 100%;
}

.table-fixed {

  thead {
    position: sticky;
    top: 0;
  }
  th {
    white-space: nowrap;
    background: #FFFFFF !important;
  }

  td {
    white-space: nowrap;
  }

  th.light-green {
    background: #92D050 !important;
  }

  th.dark-green {
    background: #00B050 !important;
  }

  th.yellow {
    background: #FFFF00 !important;
  }

  th.light-grey {
    background: #F2F2F2 !important;
  }
}

.btn-primary {
  background-color: #316F2C !important;
  border-color: #316F2C !important;
}

.date-filter {
  width: 100px !important;
}

.expand-icon {
  font-size: 1em;
  font-weight: bold;
  color: #316F2C;
}

.align-right {
  text-align: right;
}

.p-multiselect .p-multiselect-label {
  padding: 0.4rem !important;
}

.p-multiselect {
  width: 100%;
}